<script>
import crudMixin from '@/mixins/crud';
import APIProduct from '@/services/API/Product';

export default {
  mixins: [
    crudMixin('product', () => ({
      name: '',
      price: 0,
      stock: 0,
      stockEnabled: false,
      options: [],
      summary: '',
      description: '',
    }), APIProduct),
  ],
};
</script>

<template>
  <section class="section">
    <header class="is-flex is-justify-content-space-between">
      <h1 class="title">
        Produits
      </h1>
      <b-button class="flex-none" type="is-primary" @click="addProduct">
        Ajouter
      </b-button>
    </header>
    <table class="table is-bordered is-fullwidth">
      <tr>
        <th>Nom</th>
        <th class="has-text-right">Prix</th>
        <th>Options</th>
        <th>Description courte</th>
      </tr>
      <tr v-for="item in product.list" :key="item.id">
        <td>
          {{ item.name }}
        </td>
        <td class="has-text-right">
          {{ item.price }}
        </td>
        <td class="has-text-right">
          {{ item.options.map((v) => v.name).join(', ') }}
        </td>
        <td class="has-text-right">
          {{ item.summary }}
        </td>
        <td>
          <b-button
            class="has-text-danger"
            type="is-text"
            size="is-small"
            @click="deleteProduct(item)">
            Supprimer
          </b-button>
        </td>
      </tr>
    </table>
    <b-modal width="520px" :active.sync="product.isFormOpen">
      <form class="box" @submit.prevent="isDataValid() && handleProduct()">
        <h2 class="title is-4">
          Ajouter
        </h2>
        <b-field label="Nom" label-for="name">
          <b-input v-model="product.form.name" id="name" />
        </b-field>
        <b-field label="Prix" label-for="price">
          <b-input v-model.number="product.form.price" type="number" step="0.10" id="price" />
        </b-field>
        <b-field label="Description courte" label-for="summary">
          <b-input v-model="product.form.summary" id="summary" />
        </b-field>
        <b-field
          v-for="(option, k) in product.form.options"
          :key="k"
          label="Nom de l'option"
          :label-for="`name${k}`">
          <b-input v-model="option.name" size="is-small" :id="`name${k}`" expanded />
        </b-field>
        <b-field label="Ajouter des options">
          <b-button @click="product.form.options.push({name: ''})" expanded>
            Ajouter
          </b-button>
        </b-field>
        <b-field>
          <b-button type="is-primary" native-type="submit" expanded>
            Envoyer
          </b-button>
        </b-field>
      </form>
    </b-modal>
  </section>
</template>
